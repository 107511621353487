@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/media-queries';

.slider {
  width: 416px;
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  height: 100%;
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  width: 100%;

  &_image {
    display: flex;
    width: 379px;
    height: 260px;
    flex-shrink: 0;
    justify-content: center;

    image {
      border-radius: 6px;
      object-fit: cover;
      justify-content: center;
      align-items: center;
    }
  }

  &_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    h3 {
      color: #2c2d31;
      text-align: center;
      @include --bzr-h3;
      white-space: pre-line;
    }

    p {
      color: #414245;
      text-align: center;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 135%;
      white-space: pre-line;
    }
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  width: 100%;

  &_buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    button {
      @include --bzr-small-button-medium;
    }
  }
}

@include bzr-breakpoint('tablet') {
  .top {
    &_image {
      width: auto;
      height: 236px;

      img {
        height: 236px;
      }
    }

    &_text {
      width: 100%;
    }
  }
}

@include bzr-breakpoint('mobile') {
  .slider {
    width: 100%;
    height: 100%;
  }

  .slide {
    flex-shrink: 0;
    gap: 0;
  }

  .top {
    &_text {
      h3 {
        @include --bzr-h5-135;
      }

      p {
        color: #787b80;
        font-size: 14px;
        line-height: 145%;
        white-space: unset;
      }
    }
  }

  .bottom {
    gap: 16px;

    &_buttons {
      grid-template-columns: 1fr;
      gap: 12px;

      button:first-child {
        order: 2;
      }

      button:last-child,
      a {
        order: 1;
        @include --bzr-small-button-semibold;
      }
    }
  }
}
