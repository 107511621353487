.container {
  --filter-box-height: calc(100vh - 318px);
  height: var(--filter-box-height);
}

.wrapper {
  padding-inline: 10px;
}

.mobile {
  .wrapper {
    width: 100%;
  }
}

.desktop {
  width: 288px;

  .title {
    padding: 10px 6px var(--bzr-spacing-medium);
  }

  .wrapper {
    max-height: var(--filter-box-height);
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    transition: max-height 0.3s ease;

    &_fixed {
      width: 288px;
      max-height: calc(100vh - 100px);
      position: fixed;
      top: 100px;
      z-index: 1000;
    }

    &_footerShowing {
      max-height: calc(100vh - 370px);
    }
  }
}

// tablet
.tablet_filter_modal {
  padding: 20px;
  height: 90%;
  width: 100%;
}

.tablet_header_container {
  width: 100%;
  display: inline;
}

.tablet_header {
  width: 100%;
}

.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.3px;
}

.tablet {
  height: calc(100vh - 280px);
  overflow-y: scroll;
  transition: max-height 0.3s ease;

  &.tablet_footerShowing {
    transition: max-height 0.3s ease;
    height: calc(100vh - 318px);
  }

  .wrapper {
    width: 100%;
    height: 100%;
  }
}
