@import '@/styles/variables/mixins/media-queries';

.avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: linear-gradient(136deg, #0072a8 -42.65%, #00acff 85.16%);
  grid-area: avatar;
  flex-shrink: 0;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out;

  &.flipping {
    transform: rotateY(180deg);
  }

  &.first_flip {
    animation: spin360 1.5s ease-in-out;
  }

  &.relative {
    position: relative;
  }
  
  &.transparent_bg {
    background: transparent;
  }

  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;

    .avatar_img {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
        text-align: center;
        object-position: center center;
      }
      &__qr {
        border: 2px solid black;
      }
    }

    span {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }

  .back {
    z-index: 1;
    transform: rotateY(180deg);
  }

  &__online {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    width: 16px;
    height: 16px;
    background-color: var(--bzr-main-green-default);
    border: 1px solid #fff;
    border-radius: 100px;

    &__small {
      width: 12px;
      height: 12px;
    }
  }
}

@include bzr-breakpoint('tablet') {
  .avatar {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  .back .avatar_img .avatar_img__qr {
    border-width: 5px;
  }
}



@keyframes spin360 {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
