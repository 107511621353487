@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/fonts/caption';
@import '@/styles/variables/mixins/media-queries';

.ads_list_page {
  width: 100%;

  .title {
    width: 100%;
    display: flex;
    gap: var(--bzr-spacing-xs);
    margin-bottom: var(--bzr-spacing-large);

    h1 {
      @include --bzr-h1();
      color: var(--bzr-main-blue-default);
    }

    span {
      @include --bzr-caption-regular();
      font-size: 14px;
      line-height: 135%;
      color: var(--bzr-text-deep-medium-grey);
      align-self: center;
    }
  }

  .no_results {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--bzr-spacing-large);

    h1 {
      @include --bzr-h1();
      color: var(--bzr-main-blue-default);
    }

    img {
      margin: 0 auto;
    }
  }

  .sorting_wrapper {
    width: fit-content;
    border: none;
    margin-bottom: var(--bzr-spacing-premedium);

    [class*="sorting_button"] {
      padding: var(--bzr-spacing-xs) var(--bzr-spacing-small) !important;
      border-radius: var(--bzr-radius-s);
      background-color: var(--bzr-secondary-very-light-grey);

      svg:last-child {
        margin-left: 37.3px;
      }
    }
    [class*="sorting_modal"] {
      top: 32px;
    }
  }

  .recommendations {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--bzr-spacing-medium);
    margin-top: 80px;

    h2 {
      @include --bzr-h1();
      color: var(--bzr-main-blue-default);
    }

    .recommendation_list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, 227px);
      gap: var(--bzr-spacing-premedium);
    }
  }

  .container {
    display: flex;
    max-width: 1200px;
    gap: 48px;

    .filterComponentContainer {
      background: #fff;
      transition: transform 0.3s ease;
      width: 288px;
      box-sizing: border-box;
    }

    .ads_list_wrapper {
      min-width: calc(100% - 288px - 48px);
      min-height: calc(100vh - 318px);

      .ads_list {
        display: grid;
        gap: var(--bzr-spacing-medium);
      }
    }
  }
}

@media (max-width: 1200px) {
  .ads_list_page {
    .container {
      display: flex;
      width: 100%;
      position: sticky;
      overflow-y: unset;

      .ads_list_wrapper {
        max-width: calc(100% - 288px);
        width: 100%;

        .ads_list {
          position: relative;
          width: 100%;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: var(--bzr-spacing-xs);
        }

        .filterComponentContainer {
          width: 288px;
        }
      }
    }
  }
}

@include bzr-breakpoint('tablet') {
  .ads_list_page {
    width: 100%;
    position: relative;
    margin-top: 16px;

    .title {
      margin-bottom: var(--bzr-spacing-medium);
    }

    .fixed_header_mobile {
      background-color: var(--bzr-main-bg);
      position: sticky;
      top: 107px;
      z-index: 3;
      padding-bottom: 12px;

      .mobile_filters_and_sorting {
        display: flex;
        gap: var(--bzr-spacing-xxs);
        margin-bottom: var(--bzr-spacing-small);

        .filter_button,
        .sorting_wrapper {
          width: calc(50% - var(--bzr-spacing-xxs));
        }

        .filter_button {
          padding: var(--bzr-spacing-xxs) var(--bzr-spacing-premedium);
          @include --bzr-caption-medium-fontsize(12);

          svg {
            font-size: 16px;
            color: var(--bzr-text-dark-black-primary);
          }
        }

        .sorting_wrapper {
          margin-bottom: 0;

          [class*="sorting_button"] {
            width: 100%;
            @include --bzr-caption-medium-fontsize(12);

            svg:last-child {
              display: none;
            }
          }
        }
      }
    }

    .recommendations {
      h2 {
        font-size: 32px;
      }
    }

    .no_results {
      gap: var(--bzr-spacing-small);

      h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 29.26px;

        span {
          margin-left: 8px;
        }
      }
      img {
        width: 180px;
        height: 180px;
      }
    }

    .container {
      flex-direction: column;

      .ads_list_wrapper {
        max-width: 100%;
        width: 100%;

        .ads_list {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }
    }

    .sorting_wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: none;

      .filter_button {
        padding: 8px 16px;
      }
    }

    .tablet_filter {
      padding: 20px;
      height: 100%;
    }

    .filterComponentContainer {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .ads_list_page {
      .recommendations {
        h2 {
          font-size: 24px;
        }
        .recommendation_list {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .no_results {
        align-items: center;
        margin-top: 44px;

        h1 {
          text-align: center;
          color: var(--bzr-text-medium-grey);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          font-size: 14px;
          text-align: center;
          color: var(--bzr-text-medium-grey);
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        img {
          width: 150px;
          height: 150px;
        }
      }

      .sorting_wrapper {
        display: block;
      }

      .container {
        flex-direction: column;

        .ads_list_wrapper {
          max-width: 100%;

          .not_found {
            width: 100%;
            [class*='notFound_found'] {
              margin-left: auto;
            }
          }

          .ads_list {
            max-width: 100%;
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
        }
      }
    }
  }
}

@include bzr-breakpoint('mobile') {
  .ads_list_page {
    margin-top: inherit;
  }
}
