.filter_tags_list {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;

  .filter_tag {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 12px;
    border-radius: 12px;
    border: 1px solid #efefef;
    white-space: nowrap;

    .filter_title {
      font-size: 11px;
      font-weight: 500;
      line-height: 13.41px;
      text-align: left;
      color: var(--bzr-text-dark-black-primary);
    }

    .filter_value {
      font-size: 11px;
      font-weight: 500;
      line-height: 13.41px;
      color: var(--bzr-main-green-default);
      display: flex;
      align-items: center;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
